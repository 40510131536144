@import "../../variables";

.rcp-saturation {
  border-radius: unset;
}

.category-type-icons {
  .btn {
    &[aria-current="true"] {
      color: $brand-primary;
    }
    i {
      font-size: 16px;
    }
  }
}
