@import "../../variables";

.note-marker {
  min-height: 14px;
  min-width: 14px;
  max-height: 14px;
  max-width: 14px;

  &.note-user-marker {
    background-color: $user-mark-color;
  }
  &.note-group-marker {
    background-color: $group-mark-color;
  }
  &.note-global-marker {
    background-color: $general-mark-color;
  }
}
