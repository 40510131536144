@import "../../variables";

.search-filter-container {
  .accordion {
    .card {
      margin-bottom: 3px;

      .card-header {
        padding: 5px;

        .accordion-text-button {
          padding: 5px 2px;
        }
      }

      .card-body {
        padding: 5px 0 0 0;
        height: auto;
        max-height: 300px;
        overflow: auto;
        border: none;
        border-bottom: 1px dashed $brand-primary;
      }
    }
  }
}

.search-result-sorting {
  width: 280px;
  label {
    vertical-align: sub;
  }
}

.search-header-extention {
  display: flex;

  @media screen and (min-width: $breakpoint-md) {
    position: absolute;
    top: 14px;
    right: 4px;
  }
}

.search-tabs {
  .nav-item {
    .nav-link {
      border-radius: 0 40px 40px 0;
      border-color: $dark;
      color: $dark;

      &.active,
      &:hover {
        background-color: $dark;
        color: $white;
      }
    }

    &:first-of-type {
      .nav-link {
        border-radius: 40px 0 0 40px;
      }
    }
  }
}

.search-result-container {
  .result-item-header {
    font-size: inherit;
    gap: 6px;

    h3 {
      font-size: inherit;
    }

    .result-version {
      text-align: end;
      flex: 1 0 250px;
    }

    @media screen and (min-width: $breakpoint-md) {
      display: flex;
    }
  }

  .result-extract {
    @media screen and (min-width: $breakpoint-md) {
      width: 85%;
    }
  }

  .search-highlight {
    background-color: $search-highlight;
    padding: 2px 5px;
  }

  .list-group-item {
    padding: 6px 6px 6px 6px;
    margin-bottom: 10px;
  }
}
