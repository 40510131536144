@import "../../variables";

.file-up {
  display: flex;
  position: relative;
}

.file-up-label {
  flex: 0 1 200px;

  &.input-focused {
    outline: 0.15rem dashed #af6118 !important;
    outline-offset: 1px !important;
  }
}

.file-up-label-text {
  display: inline-block;
  width: 100%;
}

.file-up-input {
  height: 100%;
  right: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

.file-up-output {
  border: 1px solid $body-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.autocomplete-container {
  position: relative;

  input {
    width: 100%;
  }

  .autocomplete-results {
    padding: 0;
    position: absolute;
    top: 55px;
    z-index: 1000;
    background-color: $white;
    width: 100%;
    max-height: 280px;
    overflow: auto;

    li {
      padding: 5px 10px;
      list-style: none;
      &:not(:last-child) {
        border-bottom: 1px solid $gray-light;
      }
      &:focus {
        outline-color: $brand-primary;
      }
    }
  }
}
