@import "../../variables";

.card {
  flex: 1 1 100%;
  border: 0;
  border-radius: 0;
  border-top-left-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  .card-title {
    padding: 14px 12px;
    border-bottom: 1px solid $gray;
    margin: 0 6px;

    h2,
    h1 {
      font-size: 17px;
      margin: 0;
      font-weight: normal;

      i {
        font-size: 26px;
      }
    }

    .card-icons {
      top: 10px;
      right: 8px;

      .btn {
        padding: 6px;

        i {
          vertical-align: middle;
          font-size: 18px;
        }
      }
    }
  }

  .card-body {
    padding: $card-body-padding;
  }
}

.tab-content {
  .card {
    border-radius: 0;
  }
}
