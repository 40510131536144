@import "../variables";

.alert {
  color: $body-color;
}

.alert-warning {
  background-color: lighten($warning, 40%);
}

.alert-danger {
  background-color: lighten($danger, 50%);
}

.alert-success {
  background-color: lighten($success, 40%);
}

.alert-info {
  background-color: lighten($info, 55%);
}

.alert-secondary {
  background-color: lighten($secondary, 45%);
}

.alert-primary {
  background-color: lighten($primary, 50%);
}

.alert-dark {
  background-color: lighten($dark, 60%);
}

.alert-light {
  background-color: $light;
}
