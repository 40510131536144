@import "../variables";

.tree {
  line-height: 2;
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  .tree-branch-wrapper:focus {
    > .tree-node {
      outline: 2px dotted $focus-color;
    }
  }

  .tree-node:hover {
    outline: 2px dotted $gray-dark;
  }

  .tree-node,
  .tree-node-group {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .tree-node--focused {
    outline: 2px dotted $focus-color;
  }

  .tree-leaf-list-item--focused {
    outline: 2px dotted $focus-color;

    .tree-node--focused {
      outline: unset;
    }
  }

  .tree-node__branch {
    display: block;
  }

  .li-inner {
    padding: 2px;

    i {
      margin-top: 3px;
      font-size: 20px;
    }

    .icon-tree-collapse {
      font-size: 19px;
      margin-top: 3px;
      margin-right: 4px;
    }

    .context-menu .dropdown-toggle {
      padding-top: 0;
    }
  }

  :focus-visible {
    outline: none;
  }

  .context-menu dropdown-toggle {
    padding-right: 5px;
    padding-left: 5px;
  }
}
