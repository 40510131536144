@import "../../variables";

.last-changes-item-header {
  gap: 6px;

  p {
    flex: 1 1 100%;
  }

  .last-changes-date {
    text-align: end;
    flex: 1 0 75px;
  }

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
  }
}
