// Colors
$body-color: #444;
$body-background: #f0f2f5;
$active-color: #007dbd;
$focus-color: #1c86c8;

$gray-base: #444;
$gray-darker: #5e5e5e;
$gray-dark: #6c787f;
$gray: #cbced3;
$gray-light: #e9ecef;
$gray-lighter: #f0f2f5;

$brand-primary: #005b93;
$brand-primary-light: lighten($brand-primary, 13%);
$brand-primary-dark: darken($brand-primary, 10%);

$brand-warning: #ffb800;
$brand-warning-light: lighten($brand-warning, 10%);
$brand-warning-dark: darken($brand-warning, 10%);

$brand-danger: #bf3100;
$brand-danger-light: #f4b5a0;
$brand-danger-dark: darken($brand-danger, 10%);

$brand-success: #008853;
$brand-success-light: #e6ffe6;
$brand-success-dark: darken($brand-success, 10%);

$brand-secondary: #ee6f3a;

$brand-info: #3d3d3d;

$white: #fff;

$note-color: #ee6f3a;

//mark colors
$user-mark-color: #fedf17;
$group-mark-color: #ff0984;
$general-mark-color: #04adff;
$suggestion-mark-color: #ff9a3b;
$suggestion-content-mark-color: $gray;

//link colors
$link-color: $brand-primary;
$suggestion-link-color: #ff9a3b;

//search keyword highlight color
$search-highlight: #e2edce;

//bootstap overrides
$primary: $brand-primary;
$secondary: $brand-secondary;
$success: $brand-success;
$info: $brand-info;
$warning: $brand-warning;
$danger: $brand-danger;
$light: $gray-light;
$dark: $gray-darker;

$theme-colors: (
  primary: $primary,
  success: $success,
  info: $info,
  secondary: $secondary,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
);

/*
Sizing
*/
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
$breakpoint-ultra: 2200px;

$grid-breakpoints: (
  xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
  xxl: $breakpoint-xxl,
);

$navbar-height: 45px;

$card-body-padding: 14px;
