@import "../variables";

button:focus,
input:focus,
select:focus,
textarea:focus,
.list-group-item:focus,
a:focus {
  outline: 2px dotted $focus-color !important;
  outline-offset: 1px !important;
  box-shadow: none !important;
  z-index: 999 !important;
}

.active-li-element {
  color: $active-color !important;

  .li-content {
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
}
