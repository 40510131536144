@import "../../variables";

.article-content {
  flex: 1;

  .react-pdf__Page {
    overflow-x: auto;

    canvas {
      margin: auto;
    }
  }

  .react-pdf__Document {
    margin: 1px;
    overflow-x: auto;

    // hide text of pdf content only manipulate readabity for screenreaders
    .react-pdf__Page__textContent,
    .react-pdf__Page__annotations {
      width: 0 !important;
      height: 0 !important;
      overflow: hidden;
    }
  }

  .pdf-article-content {
    .btn.rounded-circle {
      min-width: unset;
      font-size: 16px;
      height: 36px;
      width: 36px;
      margin-left: 8px;
      padding: 2px;

      svg {
        position: relative;
        top: -2px;
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;

      .react-pdf__Document {
        flex-grow: 1;
        overflow: auto;
        margin-right: 1px;
        margin-bottom: 1px;
      }
    }
  }
}

.article-content,
.article-attachment-container {
  ins {
    background-color: $brand-success-light;
  }

  del {
    background-color: $brand-danger-light;
  }
}

.article-attachment-container {
  flex: 0 1 400px;

  // workaround so that shadow and dropdown is not cut off
  padding-left: 110px;
  margin-left: -110px;
  padding-top: 10px;
  margin-top: -10px;

  @media screen and (min-width: $breakpoint-xxl) {
    &.wide-container {
      flex: 0 1 400px;
    }
  }

  @media screen and (min-width: $breakpoint-ultra) {
    flex: 0 1 400px;
  }
}
