@import "../../variables";

.list-group-item {
  padding: 7px;
  padding-right: 30px;
  border-right: none;
  border-left: none;
  border-bottom: none;
  border-top: none;
  cursor: default;
  word-break: break-all;

  &.with-border {
    border-bottom: 1px dashed $gray;
  }

  &:first-child {
    padding-top: 0;
  }

  &:first-child,
  &:last-child {
    border-radius: unset;
  }

  .dropdown {
    position: absolute;
    right: -8px;
    top: 0;
    bottom: 0;

    .dropdown-toggle {
      padding-top: 1px;
      padding-bottom: 1px;

      svg {
        vertical-align: unset;
        position: relative;
        top: 2px;
      }
    }
  }

  i {
    font-size: 19px;
  }

  &:has(a:focus) {
    outline: 2px dotted $focus-color !important;
    outline-offset: 1px !important;
    z-index: 999;
  }

  a:focus {
    outline: none !important;
  }
}
