@import "../../variables";

.mark {
  padding: 0;
  margin: 0;
}

.suggestion-mark {
  background-color: $suggestion-mark-color;
}

#VersionContentContainer,
#SuggestionContainer {
  .mark:hover {
    cursor: pointer;
  }
}

.user-mark-version-icon {
  color: $user-mark-color;
}

.group-mark-version-icon {
  color: $group-mark-color;
}

.general-mark-version-icon {
  color: $general-mark-color;
}

.user-mark {
  background-color: $user-mark-color;
}

.user-group-mark {
  background-color: $group-mark-color;
}

.general-mark {
  background-color: $general-mark-color;
}

.suggestion-content-mark {
  background-color: $suggestion-content-mark-color;
}

.option-button {
  width: 220px;
}

.marks-button-group {
  margin: -$card-body-padding !important;
  margin-bottom: $card-body-padding !important;
  border-bottom: 1px solid $gray;
  background-color: $gray-lighter;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 7px 17px !important;

  .form-check {
    margin: 0;
    margin-left: 16px;

    .form-check-label {
      margin-top: 3px;
    }
  }

  label {
    vertical-align: text-bottom;

    span {
      padding-left: 3px;

      &.user-mark-label {
        border-left: 4px solid $user-mark-color;
      }

      &.group-mark-label {
        border-left: 4px solid $group-mark-color;
      }

      &.global-mark-label {
        border-left: 4px solid $general-mark-color;
      }
    }
  }

  @media (min-width: $breakpoint-xl) {
    display: flex;
  }
}

.marks-button-group,
.marks-button-group h3 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
}

.marks-button-group h3 {
  text-transform: uppercase;

  i {
    text-decoration: underline #6c787f;
    text-decoration-thickness: 2px;
  }
}
