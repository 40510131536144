@import "../../variables.scss";

.toast-container {
  z-index: 10000;

  .toast {
    font-size: inherit;

    &.bg-danger {
      background-color: $brand-danger-light !important;
    }

    &.bg-info {
      background-color: $gray-light !important;
    }

    &.bg-success {
      background-color: $brand-success-light !important;
    }

    .toast-header {
      color: $body-color;
    }
  }
}
