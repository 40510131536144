@import "../variables";

html,
body {
  color: $body-color;
  font-family: "Segoe UI", "Helvetica Neue", sans-serif;
  background-color: $body-background;
  line-height: 1.5;
  font-size: 14px;
}

.form-control,
.form-select {
  border-radius: unset;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-bottom: 1px solid $gray-dark;
}

.form-switch {
  padding-left: 40px;

  .form-check-input {
    background-color: $dark;
    border-color: $dark;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");

    &:checked {
      background-color: $brand-primary;
      border-color: $brand-primary;
    }
  }
}

/* Scrollbar in Firefox */
html * {
  scrollbar-color: $gray $white;
  scrollbar-width: thin;
}

/* Scrollbar in WebKit and Chromiums */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $white;
}

::-webkit-scrollbar-thumb {
  background: $gray;
  border-radius: 5px;
}

.form-check-input[type="checkbox"],
.form-check-input[type="radio"] {
  scale: 1.2;
  border: 1px solid $dark;
}

.btn-outline-dark,
.btn-outline-success,
.btn-outline-danger,
.btn-outline-primary {
  border-radius: 40px;
  padding: 5px 20px;
  border-width: 2px;
  min-width: 140px;
  background: $white;
}

.btn-hover-round {
  padding: 6px 4px 4px 4px;
  font-size: 18px;
  width: 35px;
  height: 35px;
  margin-left: 8px;
  background: unset;
  border: none;
  border-radius: 50%;

  i {
    text-align: center;
  }

  &:hover {
    background: unset;
    outline: $dark 1px solid;
  }
}

.btn.circle-btn {
  font-size: 16px;
  height: 30px;
  margin-left: 8px;
  padding: 2px;
  width: 30px;
  border-width: 2px;
  min-width: unset;
  background-color: unset;

  i {
    text-align: center;
  }

  &:hover,
  &:focus,
  &:focus-visible &.show {
    background-color: $dark;
    color: $white;
  }
}

.note-color {
  color: $note-color;
}

.user-mark-underline {
  text-decoration: underline $user-mark-color;
  text-decoration-thickness: 2px;
}

.group-mark-underline {
  text-decoration: underline $group-mark-color;
  text-decoration-thickness: 2px;
}

.global-mark-underline {
  text-decoration: underline $general-mark-color;
  text-decoration-thickness: 2px;
}

.link-mark-underline {
  text-decoration: underline $gray-dark;
  text-decoration-thickness: 2px;
}

h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus {
  outline: 0;
}

.reduce-card-padding {
  margin-left: -14px;
  margin-right: -14px;
}

.pagination {
  .page-item {
    text-align: center;
    margin-right: 5px;

    .page-link {
      color: $gray-base;
      border: 0;
      border-radius: 50%;
      background-color: unset;
      height: 38px;
      width: 38px;
      padding-top: 8px;
    }

    &.active,
    &:hover {
      .page-link {
        color: $white;
        background-color: $brand-primary;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
