@import "../../variables";

.content-inner {
  .tox-tinymce {
    border-radius: unset;
    border-right: unset;
    border-left: unset;
  }
}

.ref-icon {
  color: $link-color;
}

a.content-ref {
  color: $link-color;
}
