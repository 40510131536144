@import "../../variables";

.tree {
  .li-inner {
    &.user-group-item {
      .user-group-data {
        .user-group-inner {
          .user-group-info {
            border-left: 2px solid $brand-primary;
          }

          .icon-tree-collapse {
            position: relative;
            top: 3px;
            margin-right: 3px;
          }
        }

        @media (min-width: $breakpoint-sm) {
          display: flex;
        }
      }
    }
    &.user-item {
      .user-data {
        @media (min-width: $breakpoint-sm) {
          display: flex;
          .user-inner {
            &:first-child {
              flex: 0 1 270px;
            }
          }
        }

        .user-inner {
          .form-check-input {
            vertical-align: middle;
            margin-top: 7px;
          }
        }
      }
    }
  }
}

.permission-container {
  height: 350px;
  overflow: auto;
}
.card-body {
  .user-search-container {
    width: 300px;
    max-width: 100%;
  }
}
