@import "../variables";

.sidebar-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  box-shadow: 3px 0px 15px 0px rgba(0, 0, 0, 0.2);

  &.open {
    height: calc(100% - 47px);
    margin-top: 46px;

    @media screen and (min-width: $breakpoint-lg) {
      margin: unset;
      height: unset;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    &:not(.open) {
      position: relative;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    position: relative;
  }
}

.sidebar-content {
  width: 0;

  .sidebar-inner-toggle {
    position: absolute;
    height: 42px;
    width: 42px;
    right: 8px;
    top: 8px;
    font-size: 22px;
  }

  .sidebar-close-button {
    .btn {
      border: none;
      width: 100%;
      height: 100%;
    }

    &:hover {
      border-bottom: 3px solid $brand-primary;
      margin-bottom: -2px;

      .btn {
        color: $brand-primary;
      }
    }
  }
  .sidebar-body {
    overflow: hidden;

    .help-button:first-child {
      position: absolute;
      right: 6px;
      top: 13px;

      i {
        font-size: 20px;
      }
    }
  }

  &.open {
    height: 100%;
    width: 100vw;

    @media screen and (min-width: $breakpoint-sm) {
      width: 500px;
    }
  }

  .nav-tabs {
    margin: 2px 5px;
    border-bottom: 1px solid $gray;
    border-top: 1px solid #cbced3;

    .nav-item {
      margin-bottom: -3px;

      i {
        font-size: 22px;
      }

      .nav-link {
        width: 50px;
        border: 0;
        border-radius: unset;
        color: $body-color;
        padding: 15px;

        &.active {
          color: $brand-primary;
          border: none;
          border-bottom: 3px solid $brand-primary;
        }

        &:hover:not(.active) {
          border: none;
          border-bottom: 3px solid $body-color;
        }
      }
    }
  }

  .tab-content {
    padding-top: 5px;
    padding-right: 3px;
    height: calc(100% - 65px);

    .tab-pane {
      padding-left: 5px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 3px;
    }
  }

  .tree {
    font-size: 13px;
    margin-right: 3px;
  }

  @media screen and (min-width: $breakpoint-md) {
    width: 55px;
  }

  .sidebar-header {
    display: none;

    @media screen and (min-width: $breakpoint-md) {
      display: block;
    }
  }
}
