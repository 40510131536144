$icomoon-font-family: "lupo" !default;
$icomoon-font-path: "fonts" !default;

$icon-video: unquote('"\\e958"');
$icon-help: unquote('"\\e94a"');
$icon-info: unquote('"\\e955"');
$icon-sidebar_open: unquote('"\\e94b"');
$icon-sidebar_close: unquote('"\\e94c"');
$icon-powerpoint: unquote('"\\e941"');
$icon-alte_version: unquote('"\\e940"');
$icon-merkliste_version: unquote('"\\e900"');
$icon-version_future: unquote('"\\e94d"');
$icon-user_import: unquote('"\\e901"');
$icon-uncheck: unquote('"\\e902"');
$icon-right_new: unquote('"\\e903"');
$icon-right_system: unquote('"\\e953"');
$icon-right_category: unquote('"\\e950"');
$icon-right_user: unquote('"\\e954"');
$icon-usergroup_new: unquote('"\\e904"');
$icon-user_new: unquote('"\\e905"');
$icon-merkliste_new: unquote('"\\e906"');
$icon-merkliste_remove: unquote('"\\e95e"');
$icon-artikel_pdf_new: unquote('"\\e937"');
$icon-import_gesetz: unquote('"\\e938"');
$icon-artikel_new: unquote('"\\e939"');
$icon-folder_new: unquote('"\\e936"');
$icon-abmelden: unquote('"\\e907"');
$icon-account: unquote('"\\e908"');
$icon-aenderungen: unquote('"\\e909"');
$icon-aktivieren: unquote('"\\e90a"');
$icon-artikel: unquote('"\\e90b"');
$icon-artikel_pdf: unquote('"\\e90c"');
$icon-attachments: unquote('"\\e90d"');
$icon-benutzernotizen: unquote('"\\e90e"');
$icon-benutzerrollen: unquote('"\\e90f"');
$icon-carretdown: unquote('"\\e910"');
$icon-carretup: unquote('"\\e911"');
$icon-dashboard: unquote('"\\e912"');
$icon-deaktivieren: unquote('"\\e913"');
$icon-edit: unquote('"\\e914"');
$icon-einzel_pdf: unquote('"\\e915"');
$icon-entsperren: unquote('"\\e916"');
$icon-entwuerfe: unquote('"\\e917"');
$icon-entwurf: unquote('"\\e918"');
$icon-excel: unquote('"\\e919"');
$icon-externer_link: unquote('"\\e91a"');
$icon-folder: unquote('"\\e91b"');
$icon-folder_outline: unquote('"\\e91c"');
$icon-gesetz: unquote('"\\e91d"');
$icon-image: unquote('"\\e91e"');
$icon-interne_da: unquote('"\\e91f"');
$icon-kategorie_pdf: unquote('"\\e920"');
$icon-kategorietypen: unquote('"\\e921"');
$icon-merkliste: unquote('"\\e922"');
$icon-reg: unquote('"\\e923"');
$icon-reg_sent: unquote('"\\e95b"');
$icon-reg_active: unquote('"\\e95c"');
$icon-minus: unquote('"\\e924"');
$icon-close: unquote('"\\e94e"');
$icon-minus_framed: unquote('"\\e925"');
$icon-move: unquote('"\\e926"');
$icon-notiz: unquote('"\\e927"');
$icon-notice_marker: unquote('"\\e94f"');
$icon-pencil: unquote('"\\e952"');
$icon-notice_with_marker: unquote('"\\e951"');
$icon-password_reset: unquote('"\\e928"');
$icon-plus: unquote('"\\e929"');
$icon-plus_framed: unquote('"\\e92a"');
$icon-rl_fh: unquote('"\\e92b"');
$icon-search: unquote('"\\e92c"');
$icon-settings: unquote('"\\e92d"');
$icon-sitemap: unquote('"\\e92e"');
$icon-sperren: unquote('"\\e92f"');
$icon-stapel: unquote('"\\e930"');
$icon-trash: unquote('"\\e931"');
$icon-tree: unquote('"\\e932"');
$icon-umbenennen: unquote('"\\e933"');
$icon-users: unquote('"\\e934"');
$icon-vergleichen: unquote('"\\e935"');
$icon-verlinkungen: unquote('"\\e93a"');
$icon-version: unquote('"\\e93b"');
$icon-version_old: unquote('"\\e956"');
$icon-versionen: unquote('"\\e93c"');
$icon-versions_pdf: unquote('"\\e93d"');
$icon-wiederherstellen: unquote('"\\e93e"');
$icon-word: unquote('"\\e93f"');
$icon-touch_hold: unquote('"\\e942"');
$icon-backlink: unquote('"\\e943"');
$icon-struktur: unquote('"\\e944"');
$icon-mui: unquote('"\\e945"');
$icon-urteil: unquote('"\\e946"');
$icon-leitfaden: unquote('"\\e947"');
$icon-protokoll: unquote('"\\e948"');
$icon-schulung: unquote('"\\e949"');
$icon-check_marks: unquote('"\\e957"');
$icon-close_2: unquote('"\\e959"');
$icon-change: unquote('"\\e95a"');
$icon-video_help: unquote('"\\e95d"');

