@import "../variables";

main {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;

  .content-container {
    flex: 1 1 100%;
    margin: 55px 6px 0 0;
    overflow: hidden auto;
    padding: 10px 4px 0 10px;

    &::-webkit-scrollbar {
      border-radius: 5px;
      border: 1px dashed $gray;
      background-color: transparent;
    }

    .content-inner,
    .content-inner-article {
      align-items: flex-start;
      gap: 25px;

      // workaround so that shadow is not cut off
      padding-left: 10px;
      margin-left: -10px;
      padding-top: 10px;
      margin-top: -8px;

      @media screen and (min-width: $breakpoint-md) {
        display: flex;
      }
    }

    .content-inner-article {
      @media screen and (min-width: $breakpoint-md) {
        flex-grow: 1;
        overflow: hidden;
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      height: calc(100% - 65px);

      .content-inner-article {
        .article-content,
        .article-attachment-container {
          height: 100%;

          &::-webkit-scrollbar {
            border-radius: 5px;
            border: 1px dashed $gray;
            background-color: transparent;
          }
        }

        .article-content {
          display: flex;
          flex-direction: column;
          overflow: hidden;

          // workaround so that shadow is not cut off
          padding-left: 10px;
          margin-left: -10px;
          padding-right: 10px;
          margin-right: -10px;
          padding-top: 10px;
          margin-top: -10px;

          .card {
            display: flex;
            flex-direction: column;
            height: calc(100% - 1000px);

            .card-body {
              display: flex;
              flex-direction: column;
              overflow: hidden;
              flex-grow: 1;

              .version-content {
                overflow: auto;
                margin-right: -6px;
              }
            }
          }
        }

        .article-attachment-container {
          padding-right: 10px;
          overflow: auto;
          height: calc(100% - 60px);
        }
      }
    }

    .article-view {
      // workaround so that shadow is not cut off
      padding-left: 10px;
      margin-left: -10px;

      @media screen and (min-width: $breakpoint-md) {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        &.article-edit-container {
          .article-content {
            .card-body {
              overflow: auto;
            }
          }

          .article-attachment-container {
            height: 100%;
          }
        }
      }
    }

    @media screen and (min-width: $breakpoint-lg) {
      margin: 90px 0.4rem 10px 0px;
      height: calc(100% - 100px);
    }

    @media screen and (min-width: $breakpoint-ultra) {
      flex: 0 1 1687px;
      height: calc(100% - 110px);
      margin: 110px auto 0 auto;
    }
  }
}
