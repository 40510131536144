@import "../../../variables";

.usergroup-delete-alert {
  background-color: transparent;
  border: 1px solid $brand-danger;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  text-align: center;

  .btn {
    margin-right: 15px;
  }
}
