@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?a5sc9i');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?a5sc9i#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?a5sc9i') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?a5sc9i') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?a5sc9i##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-sidebar_open {
  &:before {
    content: $icon-sidebar_open; 
  }
}
.icon-sidebar_close {
  &:before {
    content: $icon-sidebar_close; 
  }
}
.icon-powerpoint {
  &:before {
    content: $icon-powerpoint; 
  }
}
.icon-alte_version {
  &:before {
    content: $icon-alte_version; 
  }
}
.icon-merkliste_version {
  &:before {
    content: $icon-merkliste_version; 
  }
}
.icon-version_future {
  &:before {
    content: $icon-version_future; 
  }
}
.icon-user_import {
  &:before {
    content: $icon-user_import; 
  }
}
.icon-uncheck {
  &:before {
    content: $icon-uncheck; 
  }
}
.icon-right_new {
  &:before {
    content: $icon-right_new; 
  }
}
.icon-right_system {
  &:before {
    content: $icon-right_system; 
  }
}
.icon-right_category {
  &:before {
    content: $icon-right_category; 
  }
}
.icon-right_user {
  &:before {
    content: $icon-right_user; 
  }
}
.icon-usergroup_new {
  &:before {
    content: $icon-usergroup_new; 
  }
}
.icon-user_new {
  &:before {
    content: $icon-user_new; 
  }
}
.icon-merkliste_new {
  &:before {
    content: $icon-merkliste_new; 
  }
}
.icon-merkliste_remove {
  &:before {
    content: $icon-merkliste_remove; 
  }
}
.icon-artikel_pdf_new {
  &:before {
    content: $icon-artikel_pdf_new; 
  }
}
.icon-import_gesetz {
  &:before {
    content: $icon-import_gesetz; 
  }
}
.icon-artikel_new {
  &:before {
    content: $icon-artikel_new; 
  }
}
.icon-folder_new {
  &:before {
    content: $icon-folder_new; 
  }
}
.icon-abmelden {
  &:before {
    content: $icon-abmelden; 
  }
}
.icon-account {
  &:before {
    content: $icon-account; 
  }
}
.icon-aenderungen {
  &:before {
    content: $icon-aenderungen; 
  }
}
.icon-aktivieren {
  &:before {
    content: $icon-aktivieren; 
  }
}
.icon-artikel {
  &:before {
    content: $icon-artikel; 
  }
}
.icon-artikel_pdf {
  &:before {
    content: $icon-artikel_pdf; 
  }
}
.icon-attachments {
  &:before {
    content: $icon-attachments; 
  }
}
.icon-benutzernotizen {
  &:before {
    content: $icon-benutzernotizen; 
  }
}
.icon-benutzerrollen {
  &:before {
    content: $icon-benutzerrollen; 
  }
}
.icon-carretdown {
  &:before {
    content: $icon-carretdown; 
  }
}
.icon-carretup {
  &:before {
    content: $icon-carretup; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-deaktivieren {
  &:before {
    content: $icon-deaktivieren; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-einzel_pdf {
  &:before {
    content: $icon-einzel_pdf; 
  }
}
.icon-entsperren {
  &:before {
    content: $icon-entsperren; 
  }
}
.icon-entwuerfe {
  &:before {
    content: $icon-entwuerfe; 
  }
}
.icon-entwurf {
  &:before {
    content: $icon-entwurf; 
  }
}
.icon-excel {
  &:before {
    content: $icon-excel; 
  }
}
.icon-externer_link {
  &:before {
    content: $icon-externer_link; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-folder_outline {
  &:before {
    content: $icon-folder_outline; 
  }
}
.icon-gesetz {
  &:before {
    content: $icon-gesetz; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-interne_da {
  &:before {
    content: $icon-interne_da; 
  }
}
.icon-kategorie_pdf {
  &:before {
    content: $icon-kategorie_pdf; 
  }
}
.icon-kategorietypen {
  &:before {
    content: $icon-kategorietypen; 
  }
}
.icon-merkliste {
  &:before {
    content: $icon-merkliste; 
  }
}
.icon-reg {
  &:before {
    content: $icon-reg; 
  }
}
.icon-reg_sent {
  &:before {
    content: $icon-reg_sent; 
  }
}
.icon-reg_active {
  &:before {
    content: $icon-reg_active; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-minus_framed {
  &:before {
    content: $icon-minus_framed; 
  }
}
.icon-move {
  &:before {
    content: $icon-move; 
  }
}
.icon-notiz {
  &:before {
    content: $icon-notiz; 
  }
}
.icon-notice_marker {
  &:before {
    content: $icon-notice_marker; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-notice_with_marker {
  &:before {
    content: $icon-notice_with_marker; 
  }
}
.icon-password_reset {
  &:before {
    content: $icon-password_reset; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-plus_framed {
  &:before {
    content: $icon-plus_framed; 
  }
}
.icon-rl_fh {
  &:before {
    content: $icon-rl_fh; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-sitemap {
  &:before {
    content: $icon-sitemap; 
  }
}
.icon-sperren {
  &:before {
    content: $icon-sperren; 
  }
}
.icon-stapel {
  &:before {
    content: $icon-stapel; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-tree {
  &:before {
    content: $icon-tree; 
  }
}
.icon-umbenennen {
  &:before {
    content: $icon-umbenennen; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-vergleichen {
  &:before {
    content: $icon-vergleichen; 
  }
}
.icon-verlinkungen {
  &:before {
    content: $icon-verlinkungen; 
  }
}
.icon-version {
  &:before {
    content: $icon-version; 
  }
}
.icon-version_old {
  &:before {
    content: $icon-version_old; 
  }
}
.icon-versionen {
  &:before {
    content: $icon-versionen; 
  }
}
.icon-versions_pdf {
  &:before {
    content: $icon-versions_pdf; 
  }
}
.icon-wiederherstellen {
  &:before {
    content: $icon-wiederherstellen; 
  }
}
.icon-word {
  &:before {
    content: $icon-word; 
  }
}
.icon-touch_hold {
  &:before {
    content: $icon-touch_hold; 
  }
}
.icon-backlink {
  &:before {
    content: $icon-backlink; 
  }
}
.icon-struktur {
  &:before {
    content: $icon-struktur; 
  }
}
.icon-mui {
  &:before {
    content: $icon-mui; 
  }
}
.icon-urteil {
  &:before {
    content: $icon-urteil; 
  }
}
.icon-leitfaden {
  &:before {
    content: $icon-leitfaden; 
  }
}
.icon-protokoll {
  &:before {
    content: $icon-protokoll; 
  }
}
.icon-schulung {
  &:before {
    content: $icon-schulung; 
  }
}
.icon-check_marks {
  &:before {
    content: $icon-check_marks; 
  }
}
.icon-close_2 {
  &:before {
    content: $icon-close_2; 
  }
}
.icon-change {
  &:before {
    content: $icon-change; 
  }
}
.icon-video_help {
  &:before {
    content: $icon-video_help; 
  }
}

