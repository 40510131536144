@import "../../variables";

.accordion {
  .card {
    border-radius: unset;
    box-shadow: unset;

    .card-header {
      border-radius: unset;
      background: transparent;
      border-bottom: 1px dashed $gray-dark;

      > button:not(.accordion-text-button) {
        width: 100%;
        padding: 5px 2px;

        > i {
          float: right;
          vertical-align: middle;
          font-size: 18px;
          margin-right: 2px;
        }
      }

      .accordion-icons {
        display: flex;

        button {
          padding-right: 4px;
          padding-left: 4px;

          i {
            font-size: 18px;
          }
        }

        .form-switch {
          margin-top: 4px;
        }
      }
    }
  }
}
