@import "../variables";

header {
  .navbar {
    background-color: $white;
    height: $navbar-height;
    z-index: 1000;
    padding: 5px 10px;

    .navbar-btn-container {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 8px;

      .dropdown-toggle {
        &::after {
          display: none;
        }
      }

      @media screen and (min-width: $breakpoint-xl) {
        margin-top: 15px;
      }
    }

    .header-active-background {
      background-color: $brand-primary;
      color: $white;
    }

    .input-group {
      .form-label {
        color: $white;
        margin: unset;
        padding: 5px 8px;
        font-size: 16px;
      }

      .form-control {
        border-radius: 0.25rem !important;
      }
    }

    .search-field-header {
      position: relative;
      width: 270px;
      top: 3px;
      height: 40px;
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2) !important;
      border-radius: 20px !important;

      .form-control {
        padding-left: 15px;
        border-radius: 20px 0 0 20px !important;
      }

      .search-btn {
        border-radius: 0 20px 20px 0 !important;
        .icon-search {
          position: relative;
          font-size: 20px;
          height: 34px;
          width: 34px;
          padding: 7px;
          left: 5px;
          top: 2px;
          border-radius: 50%;
        }
      }

      .search-filter-btn {
        background: transparent;
        background-color: $white;
        border-color: $white;
      }

      @media screen and (min-width: $breakpoint-xl) {
        top: 13px;
        width: 300px;
      }

      @media screen and (min-width: $breakpoint-xxl) {
        height: 40px;
        width: 550px;
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      background-color: unset;
      margin: 0 10px;
      border-bottom: 1px dashed $brand-primary;
    }

    @media screen and (min-width: $breakpoint-xl) {
      height: 65px;
      margin-right: 15px;
    }
  }

  .search-container {
    position: absolute;
    z-index: 1000;
    top: 68px;
    width: 100%;

    > div {
      background-color: $gray-lighter;
      margin: auto;
      width: 90%;
      border-bottom: 2px solid $brand-primary;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 25px 50px;
    }

    .close-search-filter {
      position: relative;
      background: unset;
      border: unset;
      top: 10px;
      right: 10px;
      color: $brand-primary;
      font-size: 24px;
      font-weight: bolder;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    margin-left: 60px;
  }

  @media screen and (min-width: $breakpoint-lg) {
    &.margin-sidebar {
      margin-left: 500px;

      .search-container {
        width: calc(100% - 515px);
      }
    }
  }
}
