@import "../../variables";

.max-350 {
  max-height: 350px;
  overflow: auto;

  .form-check-input {
    margin-top: 8px;
  }
}

.suggestion-link {
  color: $suggestion-link-color;
}
