@import "../../variables";

.context-menu {
  position: absolute;
  right: 0;
  top: 0;

  .dropdown-toggle {
    background: none;
    border: none;
    color: $body-color;

    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    button:focus-visible,
    a:focus {
      outline: 2px solid $body-color;
    }
  }
}

.icon-button {
  position: absolute;
  right: 0;
  top: 3px;
}
