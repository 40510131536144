@import "../../variables";

.modal {
  .modal-dialog {
    margin-top: 20px;

    .modal-content {
      border-radius: 0;
      border-top-left-radius: 20px !important;
      box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.9);

      .modal-header {
        background-color: $dark;
        padding: 7px 15px 10px 15px;
        color: #fff;
        border-radius: unset;
        border-top-left-radius: 20px !important;

        h2.modal-title {
          text-transform: uppercase;
          font-weight: normal !important;
          font-size: 18px;
          border-bottom: 1px dashed #37a593;
          padding-bottom: 10px;
        }
      }

      .modal-body {
        padding: 18px 14px 18px 18px;
        margin-right: 4px;
      }
    }
  }

  &.wide-dialog {
    .modal-dialog {
      @media (min-width: $breakpoint-sm) {
        max-width: unset;
        margin: 8px;
      }

      @media (min-width: $breakpoint-md) {
        margin: auto;
        margin-top: 20px;
        max-width: 750px;
      }
    }
  }
}
